

























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { FieldStateMixin } from '../../utils/FieldStateMixin';
import InvalidForm from '../shared/InvalidForm.vue';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { selected } from '../../utils/validators';

@Component({
  components: { InvalidForm },
  validations: {
    value: { required, selected },
  },
})
export default class FormAgreement extends Mixins(FieldStateMixin) {
  @Prop() public readonly value!: boolean;
  @Prop({ default: false }) public disabled: boolean;
  public $v: ValidationProperties<this> & ValidationGroups & Validation;

  public touch(): void {
    this.$v.$touch();
  }

  public invalid(): boolean {
    this.touch();
    return this.$v.$invalid;
  }

  public reset(): void {
    this.$v.$reset();
  }
}
