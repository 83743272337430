import { render, staticRenderFns } from "./AuditionForm.vue?vue&type=template&id=59a4d08c&scoped=true&"
import script from "./AuditionForm.vue?vue&type=script&lang=ts&"
export * from "./AuditionForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a4d08c",
  null
  
)

export default component.exports